import React, { useState, useEffect } from "react";
import "./EmployeeHours.scss";
import moment from "moment-timezone";

interface ClockEntry {
  clock_entry_id: number;
  employee_id: number;
  firstname: string;
  lastname: string;
  email: string;
  clock_in: string;
  clock_out: string | null;
}

const EmployeeHours: React.FC = () => {
  const [reportType, setReportType] = useState<"Daily" | "Weekly">("Weekly");
  const [reportDate, setReportDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totals, setTotals] = useState({
    totalHoursDecimal: "0.00",
    totalTabs: "0.00",
  });
  const [employeeHours, setEmployeeHours] = useState<
    {
      employee_id: number;
      firstname: string;
      lastname: string;
      totalMinutes: number;
      tabAmount: number;
      transactions: {
        orderNumber: string;
        amount: number;
        kind: string;
        date: string;
      }[];
    }[]
  >([]);
  const [detailedClockEntries, setDetailedClockEntries] = useState<
    ClockEntry[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEditEntry, setCurrentEditEntry] = useState<ClockEntry | null>(
    null
  );
  const [editedClockIn, setEditedClockIn] = useState<string>("");
  const [editedClockOut, setEditedClockOut] = useState<string>("");
  const [isAddEntryModalOpen, setIsAddEntryModalOpen] = useState(false);
  const [newEntryEmployeeId, setNewEntryEmployeeId] = useState<number | null>(
    null
  );
  const [newEntryClockIn, setNewEntryClockIn] = useState<string>(
    moment.tz("America/Chicago").format("YYYY-MM-DDTHH:mm")
  );
  const [newEntryClockOut, setNewEntryClockOut] = useState<string>(
    moment.tz("America/Chicago").format("YYYY-MM-DDTHH:mm")
  );
  const [allEmployees, setAllEmployees] = useState<
    { id: number; firstname: string; lastname: string }[]
  >([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch("/api/employees", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const employees = await response.json();
          const sortedEmployees = employees.sort((a: any, b: any) =>
            a.firstname.localeCompare(b.firstname)
          );
          setAllEmployees(sortedEmployees);
        } else {
          console.error("Failed to fetch employees.");
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  const handleAddNewEntry = async () => {
    if (!newEntryEmployeeId || !newEntryClockIn) {
      alert("Please select an employee and provide a clock-in time.");
      return;
    }

    try {
      const requestBody = {
        clock_in: moment
          .tz(newEntryClockIn, Intl.DateTimeFormat().resolvedOptions().timeZone)
          .toISOString(),
        clock_out: newEntryClockOut
          ? moment
              .tz(
                newEntryClockOut,
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .toISOString()
          : null,
      };

      const response = await fetch(
        `/api/employee/${newEntryEmployeeId}/clock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (response.ok) {
        fetchHours(); // Refresh the data
        setIsAddEntryModalOpen(false);
      } else {
        console.error(data.error || "Failed to add new entry.");
      }
    } catch (error) {
      console.error("Error adding new entry:", error);
    }
  };

  const handleEditClick = (entry: ClockEntry) => {
    setCurrentEditEntry(entry);

    // Format timestamps for datetime-local input
    const formattedClockIn = moment
      .tz(entry.clock_in, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DDTHH:mm");
    const formattedClockOut = entry.clock_out
      ? moment
          .tz(entry.clock_out, Intl.DateTimeFormat().resolvedOptions().timeZone)
          .format("YYYY-MM-DDTHH:mm")
      : "";

    setEditedClockIn(formattedClockIn);
    setEditedClockOut(formattedClockOut);
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (!currentEditEntry) return;

    try {
      const response = await fetch(
        `/api/employee/clock/${currentEditEntry.clock_entry_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            clock_in: moment
              .tz(
                editedClockIn,
                Intl.DateTimeFormat().resolvedOptions().timeZone
              )
              .toISOString(),
            clock_out: editedClockOut
              ? moment
                  .tz(
                    editedClockOut,
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                  .toISOString()
              : null,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        fetchHours();
        setIsModalOpen(false);
      } else {
        console.error(data.error || "Failed to update clock entry.");
      }
    } catch (error) {
      console.error("Error updating clock entry:", error);
    }
  };

  useEffect(() => {
    const parseLocalDate = (dateString: string): Date => {
      const [year, month, day] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day); // Month is zero-based
    };

    const selectedDate = parseLocalDate(reportDate);

    if (reportType === "Weekly") {
      const monday = findMonday(selectedDate);
      const sunday = findSunday(selectedDate);
      setStartDate(toYYYYMMDD(monday));
      setEndDate(toYYYYMMDD(sunday));
    } else if (reportType === "Daily") {
      const formattedDate = toYYYYMMDD(selectedDate);
      setStartDate(formattedDate);
      setEndDate(formattedDate);
    }
  }, [reportType, reportDate]);

  useEffect(() => {
    const calculateTotals = () => {
      let totalMinutes = 0;
      let totalTabs = 0;

      employeeHours.forEach((emp) => {
        totalMinutes += emp.totalMinutes;
        totalTabs += emp.tabAmount;
      });

      return {
        totalHoursDecimal: (totalMinutes / 60).toFixed(2),
        totalTabs: totalTabs.toFixed(2),
      };
    };

    setTotals(calculateTotals());
  }, [employeeHours]);

  const findMonday = (date: Date): Date => {
    const day = date.getDay();
    const diff = day === 0 ? 6 : day - 1;
    const monday = new Date(date);
    monday.setDate(date.getDate() - diff);
    return monday;
  };

  const findSunday = (date: Date): Date => {
    const day = date.getDay();
    const diff = day === 0 ? 0 : 7 - day;
    const sunday = new Date(date);
    sunday.setDate(date.getDate() + diff);
    return sunday;
  };

  const toYYYYMMDD = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const now = new Date();
    const temp = new Date(now);
    temp.setDate(now.getDate() - 1);
    const lastSunday = findSunday(temp);
    const mondayBeforeThat = findMonday(lastSunday);
    setStartDate(toYYYYMMDD(mondayBeforeThat));
    setEndDate(toYYYYMMDD(lastSunday));
    setReportDate(toYYYYMMDD(now));
  }, []);

  const handleDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    which: "start" | "end"
  ) => {
    const newDateVal = e.target.value;
    if (!newDateVal) return;

    const newDate = new Date(newDateVal);
    const newMonday = findMonday(newDate);
    const newSunday = findSunday(newDate);

    if (which === "start") {
      setStartDate(toYYYYMMDD(newMonday));
      setEndDate(toYYYYMMDD(newSunday));
    }
    if (which === "end") {
      setStartDate(toYYYYMMDD(newMonday));
      setEndDate(toYYYYMMDD(newSunday));
    }
  };

  const truncateToMinutes = (dateStr: string): Date => {
    const date = new Date(dateStr);
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      0,
      0
    );
  };

  const calculateDiffInMinutes = (
    clock_in_str: string,
    clock_out_str: string | null
  ): number => {
    if (!clock_out_str) return 0;

    const inTime = new Date(clock_in_str).getTime();
    const outTime = new Date(clock_out_str).getTime();

    const diff = outTime - inTime;
    return diff > 0 ? Math.floor(diff / (1000 * 60)) : 0;
  };

  const formatHoursAndMinutes = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const fetchHours = async () => {
    if (!startDate || !endDate) {
      alert("Please enter both start and end dates.");
      return;
    }

    try {
      const startFull = `${startDate}T00:00:00`;
      const endFull = `${endDate}T23:59:59`;

      // Fetch all employees
      const employeesResponse = await fetch("/api/employees", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (!employeesResponse.ok) {
        console.error("Failed to fetch employees");
        return;
      }

      const employees = await employeesResponse.json();

      // Fetch clock entries
      const clockResponse = await fetch(
        `/api/clock-entries?start=${encodeURIComponent(
          startFull
        )}&end=${encodeURIComponent(endFull)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (!clockResponse.ok) {
        console.error("Failed to fetch clock entries");
        return;
      }

      const clockEntries: ClockEntry[] = await clockResponse.json();
      setDetailedClockEntries(clockEntries); // Save clock entries for detailed table

      const employeeEmails = employees
        .map((emp: any) => emp.email)
        .filter(Boolean);

      // Fetch transactions for all employees
      const transactionsResponse = await fetch("/api/fetch-transactions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          emails: employeeEmails,
          startDate,
          endDate,
        }),
      });

      if (!transactionsResponse.ok) {
        console.error("Failed to fetch transactions");
        return;
      }

      const rawTransactions = await transactionsResponse.json();
      const transactions = rawTransactions.filter((tx) => tx.gateway === "Tab");

      const employeeMap: Record<
        number,
        {
          firstname: string;
          lastname: string;
          totalMinutes: number;
          tabAmount: number;
          transactions: any[];
        }
      > = {};

      // Initialize all employees in the map
      employees.forEach((employee: any) => {
        const { id: employee_id, firstname, lastname } = employee;
        employeeMap[employee_id] = {
          firstname,
          lastname,
          totalMinutes: 0,
          tabAmount: 0,
          transactions: [],
        };
      });

      // Add clock entries to the map
      clockEntries.forEach((entry) => {
        const { employee_id, firstname, lastname, clock_in, clock_out } = entry;
        const minutesDiff = calculateDiffInMinutes(clock_in, clock_out);

        if (!employeeMap[employee_id]) {
          employeeMap[employee_id] = {
            firstname,
            lastname,
            totalMinutes: 0,
            tabAmount: 0,
            transactions: [],
          };
        }

        employeeMap[employee_id].totalMinutes += minutesDiff;
      });

      // Add transactions to the map
      transactions.forEach((tx) => {
        const employee = employees.find((emp: any) => emp.email === tx.email);
        if (!employee) return;

        const { id: employee_id } = employee;

        const tabTotal =
          tx.kind === "REFUND"
            ? -parseFloat(tx.amountSet.presentmentMoney.amount)
            : parseFloat(tx.amountSet.presentmentMoney.amount);

        employeeMap[employee_id].tabAmount += tabTotal;
        employeeMap[employee_id].transactions.push({
          orderNumber: tx.orderId?.split("/").pop(),
          amount: parseFloat(tx.amountSet.presentmentMoney.amount),
          kind: tx.kind,
          date: tx.createdAt,
        });
      });

      const resultsArray = Object.keys(employeeMap).map((empId) => ({
        employee_id: Number(empId),
        firstname: employeeMap[Number(empId)].firstname,
        lastname: employeeMap[Number(empId)].lastname,
        totalMinutes: employeeMap[Number(empId)].totalMinutes,
        tabAmount: employeeMap[Number(empId)].tabAmount,
        transactions: employeeMap[Number(empId)].transactions,
      }));

      resultsArray.sort((a, b) => a.firstname.localeCompare(b.firstname));

      setEmployeeHours(resultsArray);
    } catch (error) {
      console.error("Error fetching employee hours and transactions:", error);
    }
  };

  const formatDecimalHours = (totalMinutes: number) => {
    const decimalHours = totalMinutes / 60;
    return decimalHours.toFixed(2); // Display with 2 decimal places
  };

  const formatToUserTimeZone = (datetime: string): string => {
    return moment
      .tz(datetime, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("YYYY-MM-DD hh:mm A");
  };

  return (
    <div className="hours-container">
      <h1>Employee Hours</h1>
      <div className="date-filters">
        <label>
          Type:
          <select
            value={reportType}
            onChange={(e) =>
              setReportType(e.target.value as "Daily" | "Weekly")
            }
          >
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
          </select>
        </label>
        <label>
          Report Date:
          <input
            type="date"
            value={reportDate}
            onChange={(e) => setReportDate(e.target.value)}
          />
        </label>
        <button className="no-print" onClick={fetchHours}>
          Go
        </button>
      </div>

      <table className="employee-hours-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th colSpan={2}>Total Hours</th>
            <th>Tab Amount</th>
          </tr>
        </thead>
        <tbody>
          {employeeHours
            .filter(
              (employee) => employee.totalMinutes > 0 || employee.tabAmount > 0
            )
            .map((emp) => (
              <tr key={emp.employee_id}>
                <td>{emp.firstname}</td>
                <td>{emp.lastname}</td>
                <td>{formatHoursAndMinutes(emp.totalMinutes)}</td>{" "}
                {/* e.g., "5h 30m" */}
                <td>
                  <strong>{formatDecimalHours(emp.totalMinutes)}</strong>{" "}
                  {/* e.g., "5.50" */}
                </td>
                <td>${emp.tabAmount.toFixed(2)}</td>
              </tr>
            ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan={3} style={{ fontWeight: "bold" }}>
              Totals:
            </td>
            <td style={{ fontWeight: "bold" }}>
              {totals.totalHoursDecimal} hours
            </td>
            <td style={{ fontWeight: "bold" }}>${totals.totalTabs}</td>
          </tr>
        </tfoot>
      </table>
      <h2>Detailed Clock Entries</h2>
      <table className="detailed-clock-entries-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Clock In</th>
            <th>Clock Out</th>
            <th>Total Minutes</th>
            <th className="no-print">Actions</th>
          </tr>
        </thead>
        <tbody>
          {detailedClockEntries.map((entry) => (
            <tr key={entry.clock_entry_id}>
              <td>{entry.firstname}</td>
              <td>{entry.lastname}</td>
              <td>{formatToUserTimeZone(entry.clock_in)}</td>
              <td>
                {entry.clock_out
                  ? formatToUserTimeZone(entry.clock_out)
                  : "Still Clocked In"}
              </td>
              <td>
                {calculateDiffInMinutes(entry.clock_in, entry.clock_out) || 0}{" "}
                mins
              </td>
              <td className="no-print">
                <button
                  className="editButton"
                  onClick={() => handleEditClick(entry)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && currentEditEntry && (
        <div className="modal">
          <div className="modal-content">
            <h3>Edit Clock Entry</h3>
            <label>
              Clock In:
              <input
                type="datetime-local"
                value={editedClockIn}
                onChange={(e) => setEditedClockIn(e.target.value)}
              />
            </label>
            <br />
            <br />
            <label>
              Clock Out:
              <input
                type="datetime-local"
                value={editedClockOut}
                onChange={(e) => setEditedClockOut(e.target.value)}
              />
            </label>
            <div className="modal-actions">
              <button onClick={handleSaveChanges}>Save</button>
              <button onClick={() => setIsModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <div className="add-entry-section no-print">
        <button
          className="addEntryButton"
          onClick={() => setIsAddEntryModalOpen(true)}
        >
          Add New Entry
        </button>
      </div>

      {isAddEntryModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Add New Clock Entry</h3>
            <label>
              Employee:
              <select
                value={newEntryEmployeeId || ""}
                onChange={(e) => setNewEntryEmployeeId(Number(e.target.value))}
              >
                <option value="" disabled>
                  Select Employee
                </option>
                {allEmployees.map((emp) => (
                  <option key={emp.id} value={emp.id}>
                    {emp.firstname} {emp.lastname}
                  </option>
                ))}
              </select>
            </label>
            <br />
            <label>
              Clock In:
              <input
                type="datetime-local"
                value={newEntryClockIn}
                onChange={(e) => setNewEntryClockIn(e.target.value)}
              />
            </label>
            <br />
            <label>
              Clock Out:
              <input
                type="datetime-local"
                value={newEntryClockOut}
                onChange={(e) => setNewEntryClockOut(e.target.value)}
              />
            </label>
            <br />
            <div className="modal-actions no-print">
              <button onClick={handleAddNewEntry}>Add Entry</button>
              <button onClick={() => setIsAddEntryModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <h2>Tab Transactions</h2>
      <table className="tab-transactions-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Order Number</th>
            <th>Date/Time</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {employeeHours.map((emp) =>
            emp.transactions?.map((tx, idx) => (
              <tr key={`${emp.employee_id}-${idx}`}>
                <td>{emp.firstname}</td>
                <td>{emp.lastname}</td>
                <td>{tx.orderNumber}</td>
                <td>{new Date(tx.date).toLocaleString()}</td>
                <td>
                  $
                  {tx.kind === "REFUND"
                    ? (tx.amount * -1).toFixed(2)
                    : tx.amount.toFixed(2)}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeHours;
