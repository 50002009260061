import React, { useState, useEffect } from "react";
import "./EmployeeTable.scss";

const EmployeeTable = () => {
  const [employees, setEmployees] = useState<any[]>([]);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [editedEmployee, setEditedEmployee] = useState<any>({});
  const [showRateHint, setShowRateHint] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const positionMap: { [key: number]: string } = {
    1: "1) Ride Operator",
    2: "2) Front",
    3: "3) Kitchen",
    4: "4) Janitor",
    5: "5) Maintenance",
  };

  // Calculate pay rate (example logic)
  const calculateRateAmount = (rate: number) => 9.0 + (rate - 1) * 0.25;

  // Fetch all employees, then fetch store credit
  const fetchEmployees = async () => {
    try {
      const response = await fetch("/api/employees", {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        console.error("Failed to fetch employees");
        return;
      }

      const data = await response.json();
      // Now fetch store credit for each employee (in parallel) and merge
      const employeesWithCredit = await fetchStoreCreditForEmployees(data);
      setEmployees(employeesWithCredit);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Regex for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const fetchStoreCreditForEmployees = async (employeeList: any[]) => {
    return Promise.all(
      employeeList.map(async (emp) => {
        // Skip if no email
        if (!emp.email) {
          return { ...emp, storeCredit: null };
        }

        try {
          // Try to fetch store credit for this employee's email
          const res = await fetch(`/api/customers/${emp.email}/store-credit`, {
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          });

          // 404 means "No Shopify customer" => optionally create new
          if (res.status === 404) {
            console.warn(`No Shopify customer found for: ${emp.email}`);
            if (emailRegex.test(emp.email)) {
              console.log(
                `Attempting to create new Shopify customer for ${emp.email}...`
              );
              await createNewShopifyCustomer(emp);
              return {
                ...emp,
                storeCredit: { amount: 0, currencyCode: "USD", id: null },
              };
            } else {
              console.warn(`Invalid email format for: ${emp.email}`);
              return {
                ...emp,
                storeCredit: null,
              };
            }
          }

          // If another error code, default to zero or null
          if (!res.ok) {
            console.warn(
              `Error fetching store credit, status: ${res.status} for ${emp.email}`
            );
            return {
              ...emp,
              storeCredit: { amount: 0, currencyCode: "USD", id: null },
            };
          }

          // Otherwise parse successful store credit response
          const storeCreditData = await res.json();
          return {
            ...emp,
            storeCredit: {
              amount: storeCreditData.balance,
              currencyCode: storeCreditData.currencyCode,
              id: storeCreditData.id,
            },
          };
        } catch (error) {
          console.error(`Error fetching store credit for ${emp.email}:`, error);
          // Fallback: no store credit set
          return { ...emp, storeCredit: null };
        }
      })
    );
  };

  // Helper function to create a new Shopify customer
  const createNewShopifyCustomer = async (employee: any) => {
    try {
      const response = await fetch("/api/customer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          firstName: employee.firstname || employee.first_name || "New",
          lastName: employee.lastname || employee.last_name || "Employee",
          email: employee.email,
        }),
      });

      if (!response.ok) {
        console.error(
          `Failed to create new Shopify customer for ${employee.email}`
        );
        return null;
      }

      const data = await response.json();
      console.log("New Shopify customer created:", data.shopifyCustomer);
      return data.shopifyCustomer;
    } catch (error) {
      console.error("Error creating new Shopify customer:", error);
      return null;
    }
  };

  // Enter edit mode for an employee
  const handleEditClick = (employee: any) => {
    setEditingId(employee.id);
    setEditedEmployee({
      ...employee,
      storeCreditChange: 0, // default to 0 for the new field
    });
  };

  // Cancel editing
  const handleCancelClick = () => {
    setEditingId(null);
    setEditedEmployee({});
  };

  // Handle input changes for the edited employee
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: string
  ) => {
    let value: any;
    if (e.target.type === "checkbox") {
      value = (e.target as HTMLInputElement).checked;
    } else if (e.target.type === "number") {
      value = parseFloat(e.target.value);
    } else {
      value = e.target.value;
    }
    setEditedEmployee((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Save changes for the employee
  const handleSaveClick = async () => {
    if (!editingId) return;

    try {
      const response = await fetch(`/api/employee/${editingId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
        body: JSON.stringify(editedEmployee),
      });

      if (!response.ok) {
        console.error("Failed to save employee");
        return;
      }

      const updatedEmployee = await response.json();

      // Update employees list in state
      setEmployees((prev) =>
        prev.map((emp) => (emp.id === editingId ? updatedEmployee : emp))
      );

      setEditingId(null);
      setEditedEmployee({});
    } catch (error) {
      console.error("Error saving employee:", error);
    }
  };

  const handleStoreCreditUpdate = async (
    storeCreditOrCustomerId: string,
    amount: number,
    type: "credit" | "debit"
  ) => {
    if (!storeCreditOrCustomerId || !amount) {
      console.log("Missing ID or amount for store credit operation.");
      return;
    }
    try {
      const response = await fetch(
        `/api/customers/${encodeURIComponent(
          storeCreditOrCustomerId
        )}/store-credit`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            amount: amount.toString(),
            currencyCode: "USD",
            type: type,
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to update store credit");
        return;
      }

      const data = await response.json();
      console.log(`Store credit ${type} successful:`, data);

      // Now update your UI
      const updatedCredit = {
        amount: data.balance.amount,
        currencyCode: data.balance.currencyCode,
        id: data.id, // might be store credit account ID
      };

      setEmployees((prev) =>
        prev.map((emp) => {
          // If your logic is to match by ID in memory or something else
          // For example, if this is the currently editing employee:
          if (emp.id === editingId) {
            return {
              ...emp,
              storeCredit: updatedCredit,
            };
          }
          return emp;
        })
      );
    } catch (error) {
      console.error("Error updating store credit:", error);
    }
  };

  return (
    <div className="table-container">
      <h1>Employee Management</h1>

      <table className="employee-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>Middlename</th>
            <th>Nickname</th>
            <th>Code</th>
            <th>Email</th>
            <th>Admin</th>
            <th>Manager</th>
            <th>Front</th>
            <th>Kitchen</th>
            <th>Food Discount</th>
            <th>Discount</th>
            <th>Position</th>
            <th>Rate</th>
            <th>Actions</th>
            {/* New column: display store credit balance */}
            <th>Store Credit Balance</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => {
            const isEditing = employee.id === editingId;

            return (
              <tr key={employee.id} className={isEditing ? "editing-row" : editingId? "hidden-row":""}>
                <td>{employee.id}</td>
                {isEditing ? (
                  <>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.firstname || ""}
                        onChange={(e) => handleInputChange(e, "firstname")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.lastname || ""}
                        onChange={(e) => handleInputChange(e, "lastname")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.middlename || ""}
                        onChange={(e) => handleInputChange(e, "middlename")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.nickname || ""}
                        onChange={(e) => handleInputChange(e, "nickname")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.code || ""}
                        onChange={(e) => handleInputChange(e, "code")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={editedEmployee.email || ""}
                        onChange={(e) => handleInputChange(e, "email")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={editedEmployee.admin || false}
                        onChange={(e) => handleInputChange(e, "admin")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={editedEmployee.manager || false}
                        onChange={(e) => handleInputChange(e, "manager")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={editedEmployee.front || false}
                        onChange={(e) => handleInputChange(e, "front")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={editedEmployee.kitchen || false}
                        onChange={(e) => handleInputChange(e, "kitchen")}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={editedEmployee.fooddiscount || 0}
                        onChange={(e) => handleInputChange(e, "fooddiscount")}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={editedEmployee.discount || 0}
                        onChange={(e) => handleInputChange(e, "discount")}
                      />
                    </td>
                    <td>
                      <select
                        value={editedEmployee.position || ""}
                        onChange={(e) => handleInputChange(e, "position")}
                      >
                        <option value="">Select Position</option>
                        <option value="1">1. Ride Operator</option>
                        <option value="2">2. Front</option>
                        <option value="3">3. Kitchen</option>
                        <option value="4">4. Janitor</option>
                        <option value="5">5. Maintenance</option>
                      </select>
                    </td>
                    <td className="rate-input-cell">
                      <div className="rate-input-container">
                        <input
                          type="number"
                          value={editedEmployee.rate || ""}
                          onChange={(e) => handleInputChange(e, "rate")}
                          onFocus={() => setShowRateHint(true)}
                          onBlur={() => setShowRateHint(false)}
                        />
                      </div>
                    </td>
                    <td>
                      <button className="save-button" onClick={handleSaveClick}>
                        Save
                      </button>
                      <button
                        className="cancel-button"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                    </td>
                    {/* Store credit balance (read-only in editing mode) */}
                    <td style={{ textAlign: "center" }}>
                      {employee.storeCredit
                        ? `${employee.storeCredit.amount} ${employee.storeCredit.currencyCode}`
                        : "—"}
                      <div style={{ marginTop: "0.5rem" }}>
                        <input
                          type="number"
                          value={editedEmployee.storeCreditChange || 0}
                          onChange={(e) =>
                            handleInputChange(e, "storeCreditChange")
                          }
                          placeholder="Credit + or -"
                          style={{ width: "100px" }}
                        />
                        <button
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() =>
                            handleStoreCreditUpdate(
                              editedEmployee.storeCredit?.id, // <--- pass the storeCredit ID
                              editedEmployee.storeCreditChange,
                              "credit"
                            )
                          }
                        >
                          Add Credit
                        </button>
                        <button
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() =>
                            handleStoreCreditUpdate(
                              editedEmployee.storeCredit?.id, // <--- pass the storeCredit ID
                              editedEmployee.storeCreditChange,
                              "debit"
                            )
                          }
                        >
                          Subtract Credit
                        </button>
                      </div>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{employee.firstname}</td>
                    <td>{employee.lastname}</td>
                    <td>{employee.middlename}</td>
                    <td>{employee.nickname}</td>
                    <td>{employee.code}</td>
                    <td>{employee.email}</td>
                    <td>{employee.admin ? "Yes" : "No"}</td>
                    <td>{employee.manager ? "Yes" : "No"}</td>
                    <td>{employee.front ? "Yes" : "No"}</td>
                    <td>{employee.kitchen ? "Yes" : "No"}</td>
                    <td>{employee.fooddiscount}</td>
                    <td>{employee.discount}</td>
                    <td>{positionMap[employee.position] || ""}</td>
                    <td>{employee.rate}</td>
                    <td>
                      <button
                        className="edit-button"
                        onClick={() => handleEditClick(employee)}
                      >
                        Edit
                      </button>
                    </td>
                    {/* Display store credit in read-only mode */}
                    <td style={{ textAlign: "center" }}>
                      {employee.storeCredit
                        ? `$${Number(employee.storeCredit.amount).toFixed(2)}`
                        : "—"}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
