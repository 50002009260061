import React, { useState, useEffect, useRef } from "react";
import "./ButtonGrid.scss";
import * as Types from "../POSWindow/POSTypes";
import defaultphoto from "./default_photo.png";
import nophoto from "../POSWindow/no_photo.png";
import PhotoUploadForm from "../CameraModal/PhotoUploadForm";
import { soundManager } from "../POSWindow/sounds/soundManager";
import { processSubscriptionData } from "../MembersTable/processSubscriptionData";

interface ButtonGridProps {
  thisOrderItems: Types.ExtendedLineItem[];
  setThisOrderItems: (newOrder: Types.ExtendedLineItem[]) => void;
  typedValue: string;
  setTypedValue: (value: string) => void;
  orderId: number;
  members: Array<Types.Member>;
  setMembers(members: Types.Member[]);
  orderSearchResults: Array<Types.Order>;
  setOrder: (order: Types.Order) => void;
  searchMembers: (term: string | number, force?: boolean) => void;
  clearOrder: (maintain?: boolean, openDrawer?: boolean) => void;
  subs: Types.Subscription[];
  subId: number[] | string[] | null;
  giftCards: Types.GiftCard[];
  orderNumber: number;
  isBFF: boolean;
  scannedItem: Types.ExtendedLineItem | null;
  setScannedItem: (scannedItem: Types.ExtendedLineItem | null) => void;
  triggerReset: number;
  isDelete: boolean;
  setIsDelete: (value: boolean) => void;
  currentTab: number;
  setCurrentTab: (value: number) => void;
  discountCodes: Types.DiscountCode[];
  setDiscountCodes: (discountCodes: Types.DiscountCode[]) => void;
  customer?: Types.Customer
  setCustomer?: (customer: Types.Customer) => void
}

const ButtonGrid = ({
  thisOrderItems,
  setThisOrderItems,
  typedValue,
  setTypedValue,
  orderId,
  members,
  orderSearchResults,
  setOrder,
  searchMembers,
  clearOrder,
  subs,
  subId,
  giftCards,
  setMembers,
  orderNumber,
  isBFF,
  scannedItem,
  setScannedItem,
  triggerReset,
  isDelete,
  setIsDelete,
  currentTab,
  setCurrentTab,
  discountCodes,
  setDiscountCodes,
  customer,
  setCustomer
}: ButtonGridProps) => {
  const [buttonlist, setButtonlist] = useState<Types.ExtendedLineItem[]>([]);
  const [numOfMods, setNumOfMods] = useState(0);
  const [modClass, setModClass] = useState<number | undefined>(0);
  const [parentModClass, setParentModClass] = useState<number | undefined>(0);
  const [modifyingItemIndex, setModifyingItemIndex] = useState<number | null>(
    null
  );
  const [triggerMembers, setTriggerMembers] = useState(0);
  const [triggerOrderSearch, setTriggerOrderSearch] = useState(0);
  const [reqMods, setReqMods] = useState<number[]>([]);
  const [optMods, setOptMods] = useState<number[]>([]);
  const [allMods, setAllMods] = useState<number[]>([]);
  const [modItems, setModItems] = useState<Types.ExtendedLineItem[]>([]);

  const [parentMods, setParentMods] = useState<Types.ExtendedLineItem[]>([]);
  const [inputValues, setInputValues] = useState({});
  //if (!buttonlist) { setButtonlist(admission) }
  const [buttons, setButtons] = useState([]);
  //let buttons = [];
  //const buttonlist: LineItem[] = admission;
  const buttonarea: string[] = [];
  const [isFetchingMods, setIsFetchingMods] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Types.Member | null>(
    null
  );
  const [showPhotoUploadForm, setShowPhotoUploadForm] = useState(false);
  const [editedMember, setEditedMember] =
    useState<Types.ExtendedLineItem | null>(null);

  const [isEditMember, setIsEditMember] = useState(false);
  const [isAddBarcode, setIsAddBarcode] = useState(false);
  const [triggerGCs, setTriggerGCs] = useState(0);
  const [triggerButtonBuild, setTriggerButtonBuild] = useState(0);
  const [tabData, setTabData] = useState({});
  const [modData, setModData] = useState({});
  const [modClassesData, setModClassesData] = useState({});
  const [creatingForm, setCreatingForm] = useState(false);
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [selectedPLUItem, setSelectedPLUItem] =
    useState<Types.ExtendedLineItem | null>(null);
  const [barcode, setBarcode] = useState("");
  const [contextMenu, setContextMenu] = useState<{
    visible: boolean;
    x: number;
    y: number;
    currentItem: Types.ExtendedLineItem | null;
  }>({ visible: false, x: 0, y: 0, currentItem: null });
  const [modUpdateTrigger, setModUpdateTrigger] = useState(false);
  const barcodeBufferRef = useRef("");
  const timeoutRef = useRef(null);
  const [bufferedBarcode, setBufferedBarcode] = useState("");

  // Add event listeners to track Delete/Backspace keydown and keyup
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        setIsDelete(true); // Set isDelete to true when either key is held down
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Delete" || event.key === "Backspace") {
        setIsDelete(false); // Reset isDelete when the keys are released
      }
    };

    // Attach event listeners to the window object
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    //console.log('isDelete', isDelete);
  }, [isDelete]);

  const processBarcode = () => {
    const barcode = barcodeBufferRef.current;
    setBufferedBarcode(barcode);
    //console.log('Processing barcode:', barcode);
    // Process the barcode
    //barcodeBufferRef.current = ''; // Reset buffer after processing
  };

  useEffect(() => {
    const fetchModifiersForButtons = async () => {
      // Get all required and optional mod classes from the buttons
      const allModClasses = buttonlist.reduce((acc, button) => {
        const requiredMods = button.required_mods || [];
        const optionalMods = button.optional_mods || [];
        return acc.concat(requiredMods, optionalMods);
      }, []);
      // Remove duplicates
      const uniqueModClasses = [...new Set(allModClasses)];

      // Check which mod classes are not in the cache
      const modsNotInCache = uniqueModClasses.filter(
        (modClass) => !(modClass in modClassesData)
      );

      if (modsNotInCache.length > 0) {
        try {
          const response = await fetch(
            `/api/get-mods?modClasses=${modsNotInCache.join(",")}`,
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          );
          const modsFetchedFromServer = await response.json();
          // Organize fetched mods by modClass
          const fetchedModsByClass = {};
          modsFetchedFromServer.forEach((mod) => {
            const modClass = mod.mod;
            if (!fetchedModsByClass[modClass]) {
              fetchedModsByClass[modClass] = [];
            }
            fetchedModsByClass[modClass].push(mod);
          });

          // Ensure all fetched mod classes are present in fetchedModsByClass
          modsNotInCache.forEach((modClass) => {
            if (!(modClass in fetchedModsByClass)) {
              fetchedModsByClass[modClass] = [];
            }
          });

          // Update the cache with the newly fetched mods (including empty arrays)
          setModClassesData((prevData) => ({
            ...prevData,
            ...fetchedModsByClass,
          }));
        } catch (error) {
          console.error("Failed to fetch mods:", error);
        }
      }
    };

    const fetchModifiersForButtonList = async () => {
      // Collect all mod classes from your buttons
      const modClassesToFetch = [];

      for (const button of buttonlist) {
        // Collect modClass from buttons
        if (button.modClass) {
          modClassesToFetch.push(button.modClass);
        }
        // Collect required and optional mods
        if (button.required_mods) {
          modClassesToFetch.push(...button.required_mods);
        }
        if (button.optional_mods) {
          modClassesToFetch.push(...button.optional_mods);
        }
      }

      // Remove duplicates
      const uniqueModClasses = [...new Set(modClassesToFetch)];

      // Filter out mod classes that are already cached
      const modsNotInCache = uniqueModClasses.filter(
        (modClass) => !(modClass in modData)
      );

      if (modsNotInCache.length > 0) {
        try {
          // Fetch modifiers for each modClass
          const fetchPromises = modsNotInCache.map(async (modClass) => {
            const response = await fetch(`/api/get-pos-mods/${modClass}`, {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            });
            const posMods = await response.json();
            return { modClass, posMods };
          });

          const fetchedModsArray = await Promise.all(fetchPromises);

          // Update modData with the fetched modifiers
          setModData((prev) => {
            const newModData = { ...prev };
            for (const { modClass, posMods } of fetchedModsArray) {
              newModData[modClass] = posMods;
            }
            return newModData;
          });
        } catch (error) {
          console.error("Failed to fetch modifiers:", error);
        }
      }
    };

    if (buttonlist.length > 0) {
      fetchModifiersForButtonList();
      fetchModifiersForButtons();
    }
  }, [buttonlist]);

  useEffect(() => {
    //console.log('new barcode', bufferedBarcode);
    //wait for 1 second then clear the barcode
    if (bufferedBarcode) {
      setTimeout(() => {
        setBufferedBarcode("");
        barcodeBufferRef.current = "";
      }, 500);
    }
  }, [bufferedBarcode]);

  useEffect(() => {
    const handleKeydown = (event) => {
      // Check if the keydown event is from the barcode scanner (or a rapid input source)
      if (event.key?.length === 1) {
        // assuming barcode characters are single characters like numbers or letters
        barcodeBufferRef.current += event.key;

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(processBarcode, 100); // adjust delay as needed
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleRightClick = (event, item) => {
    event.preventDefault(); // Prevent the default context menu
    //console.log('right click on', item);
    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      currentItem: item,
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (contextMenu.visible && !event.target.closest(".context-menu")) {
        setContextMenu({ visible: false, x: 0, y: 0, currentItem: null });
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [contextMenu.visible]);

  useEffect(() => {
    if (scannedItem) {
      //console.log("scanned item", scannedItem)
      addItem(scannedItem);
      setScannedItem(null);
    }
  }, [scannedItem]);

  useEffect(() => {
    //setCurrentTab(1);
    setNumOfMods(0);
    //console.log("triggerReset: ", triggerReset);
    setModItems([]);
    setParentModClass(null);
    setModUpdateTrigger((prev) => !prev);
    setTriggerButtonBuild((prev) => prev + 1);
    setAllMods([]);
    setModClass(null);
    setNumOfMods(0);
  }, [triggerReset]);

  useEffect(() => {
    if (members.length > 0 && currentTab === 7) {
      //setCurrentTab(7);
      const memberButtons = members.map((member) => {
        const visitsFromVisitsArray = member.visits_array
          ? member.visits_array.filter((visit) => {
              const visitDate = new Date(visit);
              const today = new Date();
              return visitDate.toDateString() === today.toDateString();
            }).length
          : 0;
        const visitsFromOrderItems = thisOrderItems.reduce((count, item) => {
          return (
            count +
            (item.membership_number === member.membership_number ? 1 : 0)
          );
        }, 0);
        const totalVisitsToday = visitsFromVisitsArray + visitsFromOrderItems;

        let sub;
        let updatedMember:Types.Member = member;
        if (member.sub_id && subs.length > 0) {
          sub = subs.find((sub) => Number(sub.id) === Number(member.sub_id));
          if(sub){
            console.log('calling pSD!')
            updatedMember = 
            {...member,
              ...processSubscriptionData(sub,member)
            };
            console.log('we have an updated member!',updatedMember)
          }
        }

        console.log('valid until from button grid: ', member.valid_until, member.valid_until_string);

        const memberObject = {
          name: member.name||"No Name",
          title: `${member.membership_type} Check-In`,
          attendance_category: "Member",
          calendar_category: "Member",
          barcode: member.barcode || null,
          price: 0,
          quantity: 1,
          photo: member.photo || defaultphoto,
          hasPhoto: member.photo == nophoto ? false : true,
          membership_type: member.membership_type,
          membership_number: member.membership_number,
          status: updatedMember.status || member.status || null,
          due_date: new Date(updatedMember.due_date||member.due_date) || null,
          next_payment: new Date(updatedMember.due_date||member.due_date)||null,
          valid_until:new Date(updatedMember.valid_until||member.valid_until)||null,
          valid_until_string: updatedMember.valid_until_string||member.valid_until?.toString().split('T')[0]||null,
          max_quantity: 1,
          sub_id: member.sub_id,
          sub: sub,
          dob: member.dob,
          dobstring: member.dob ? member.dob.split("T")[0] : null,
          signup_date: new Date(updatedMember.signup_date||member.signup_date)||null,
          payments_remaining: updatedMember.payments_remaining || null,
          payment_amount: updatedMember.payment_amount||null,
          edit_url: updatedMember.edit_url||null,
          valid_starting: new Date(updatedMember.valid_starting||member.valid_starting)||null,
          properties: [
            {
              name: "membership_number",
              value: member.membership_number.toString(),
            },
            {
              name: "name",
              value: member.name,
            },
          ],
          function: "checkIn",
          ...(member.visits_array?.length
            ? { last_visit: new Date(member.visits_array[0]) }
            : {}),
          ...(member.visits_array?.length
            ? { visits: member.visits_array?.length }
            : {}),
          visitsToday: totalVisitsToday,
          currentVisits: visitsFromOrderItems,
          totalPaid: updatedMember.total_paid||null,
          alert: member.alert || null,
          email: updatedMember.email || null,
        };
        return memberObject;
      });
      setButtonlist(memberButtons);
    } else if (currentTab == 7) setCurrentTab(1);
  }, [members, subs, triggerMembers, thisOrderItems]); //trigger creation of member buttons when loaded members change

  useEffect(() => {
    if (giftCards.length > 0) {
      const gcButtons: Types.ExtendedLineItem[] = giftCards.map(
        (card: Types.GiftCard) => {
          return {
            title: "Gift Card",
            price: 0,
            quantity: 1,
            max_quantity: 1,
            properties: [
              {
                name: "Items",
                value: card.items,
              },
              {
                name: "Card Number",
                value: card.card_number,
              },
            ],
            function: "redeemGC",
            attendance_category: "Gift Card",
            valid_until_string: new Date(
              card.expiration || "12/31/3000"
            ).toISOString(),
            valid_until: new Date(card.expiration || "12/31/3000"),
			      valid_starting: new Date(card.valid_starting || "1/1/2000"),
            redeemed: card.redeem_timestamp || null,
          };
        }
      );
      //console.log("gcbuttons: ", gcButtons);
      if (giftCards.length > 0 && currentTab == 9) {
        //setCurrentTab(9);
        setButtonlist(gcButtons);
      }
    } else if (currentTab == 9) setCurrentTab(1); //if no giftCards return to tab 1
  }, [giftCards, triggerGCs]); //trigger creation of gift cards

  useEffect(() => {
    if (orderSearchResults.length > 0 && currentTab == 6) {
      const orderButtons = orderSearchResults.map((order: Types.Order) => {
        const items = order.line_items || [];
        const itemsList = items.map((item) => {
          return `${item.quantity}x${item.title}`;
        });
        let title = " ";
        if (order.customer) {
          title = order.customer?.first_name + " " + order.customer?.last_name;
        } else if (order.order_number) {
          title = `Order ${order.order_number}`;
        }
        return {
          title: title,
          subtitle: itemsList.toString(),
          barcode: order.id,
          function: "searchOrders",
          order: order,
          price: 0,
          quantity: 0,
          date: new Date(order.created_at).toLocaleDateString(),
          status: order.cancelled_at ? "Cancelled" : order.fulfillment_status,
        };
      });
      setButtonlist(orderButtons);
    }
  }, [orderSearchResults, triggerOrderSearch]); //trigger creation of order search buttons

  useEffect(() => {
    const fetchMods = async (modClassToFetch) => {
      //console.log('running fetchMods for: ', modClassToFetch);
      if (modData[modClassToFetch]) {
        // Return a cloned copy of the cached modifiers
        //console.log('cached modData: ', modData[modClassToFetch], 'from: ', modData);
        return modData[modClassToFetch].map((mod) => ({ ...mod }));
      }
      try {
        const response = await fetch(`/api/get-pos-mods/${modClassToFetch}`, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        const posMods = await response.json();
        if (posMods.length > 0) {
          //console.log('posMods fetched: ', posMods);
          // Cache the fetched modifiers
          setModData((prev) => ({ ...prev, [modClassToFetch]: posMods }));
          // Return a cloned copy of the fetched modifiers
          return posMods.map((mod) => ({ ...mod }));
        } else {
          return [];
        }
      } catch (error) {
        console.error("Failed to fetch mods:", error);
        return [];
      }
    };

    const mergeParentMods = (mods, parentMods) => {
      const merged = [...mods];
      let parentIndex = 0;
      for (let i = 0; i < merged.length; i++) {
        if (!merged[i].title && parentMods[parentIndex]) {
          merged[i] = parentMods[parentIndex];
          parentIndex++;
        }
        if (parentIndex >= parentMods.length) break;
      }
      return merged;
    };

    const handleModsFetch = async () => {
      let parentMods = [];
      if (parentModClass && parentModClass > 0) {
        const fetchedParentMods = await fetchMods(parentModClass);
        parentMods = fetchedParentMods.map((mod) => ({ ...mod })); // Clone each mod object
        // Modify parentMods without affecting cached data
        //console.log('parentMods: ', parentMods);
        for (let i = 0; i < parentMods.length; i++) {
          let modCount = 0;
          if (parentMods[i].max_quantity && buttonlist.length > 0) {
            const lastItem = thisOrderItems[thisOrderItems.length - 1];
            for (let j = 0; j < lastItem?.properties?.length; j++) {
              if (lastItem.properties[j].value == parentMods[i].title) {
                modCount++;
              }
            }
            if (modCount >= parentMods[i].max_quantity) {
              // Instead of setting to null, mark as excluded
              parentMods[i] = { ...parentMods[i], excluded: true };
            }
          }
          if (parentMods[i].mod_class && parentMods[i].mod_class !== 255000) {
            if (Math.floor(parentMods[i].mod_class / 1000) !== modClass) {
              parentMods[i] = { ...parentMods[i], excluded: true };
            }
          }
        }
        // Filter out excluded mods
        parentMods = parentMods.filter((mod) => !mod.excluded);
      }

      let theseModItems = modItems.map((mod) => ({ ...mod })); // Clone modItems
      if (theseModItems.length > 0) {
        for (let i = 0; i < theseModItems.length; i++) {
          let modCount = 0;
          if (theseModItems[i].max_quantity && buttonlist.length > 0) {
            const lastItem = thisOrderItems[thisOrderItems.length - 1];
            for (let j = 0; j < lastItem?.properties?.length; j++) {
              if (lastItem.properties[j].value == theseModItems[i].title) {
                modCount++;
              }
            }
            if (modCount >= theseModItems[i].max_quantity) {
              // Mark as excluded
              theseModItems[i] = { ...theseModItems[i], excluded: true };
            }
          }
        }
        // Filter out excluded mods
        theseModItems = theseModItems.filter((mod) => !mod.excluded);
      }

      if (modClass > 0 && (currentTab == 5 || numOfMods > 0)) {
        const fetchedMods = await fetchMods(modClass);
        const mods = fetchedMods.map((mod) => ({ ...mod })); // Clone each mod object
        mods.sort((a, b) => (a.sku > b.sku ? 1 : -1));
        const mergedMods = mergeParentMods(
          mergeParentMods(mods, parentMods),
          theseModItems
        );
        setButtonlist(mergedMods);
      } else if (numOfMods > 0 && modClass == 0) {
        setCurrentTab(5);
        setNumOfMods(0);
      } else if (currentTab <= 4 || currentTab == 11) {
        if (tabData[currentTab]) {
          const mergedMods = mergeParentMods(
            mergeParentMods(tabData[currentTab], parentMods),
            theseModItems
          );
          setButtonlist(mergedMods);
        } else {
          try {
            const response = await fetch(`/api/get-pos-by-tab/${currentTab}`, {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            });
            const posButtons = await response.json();
            const mergedMods = mergeParentMods(
              mergeParentMods(posButtons, parentMods),
              theseModItems
            );
            setButtonlist(mergedMods);
            setTabData((prev) => ({ ...prev, [currentTab]: posButtons }));
          } catch (error) {
            console.error("Failed to fetch POS tabs:", error);
          }
        }
      } else if (currentTab == 5) {
        setCurrentTab(1);
      } else if (currentTab == 7) {
        setTriggerMembers((prev) => prev + 1);
      } else if (currentTab == 6) {
        setTriggerOrderSearch((prev) => prev + 1);
      } else if (currentTab == 8) {
        return;
      } else if (currentTab == 9) {
        setTriggerGCs((prev) => prev + 1);
      } else {
        setButtonlist([]);
        setNumOfMods(0);
      }
      if (currentTab != 8) {
        setInputValues({});
      }
      if (currentTab == 10) {
        const fetchedMods = await fetchMods(255);
        const mods = fetchedMods.map((mod) => ({ ...mod })); // Clone each mod object
        mods.sort((a, b) => a.title.localeCompare(b.title));
        setButtonlist(mods);
      }
    };

    handleModsFetch();
  }, [numOfMods, currentTab, modUpdateTrigger, parentModClass]); // Fetch buttons on tab set

  useEffect(() => {
    setModUpdateTrigger((prev) => !prev);
  }, [modItems]);

  // useEffect for initializing input values
  useEffect(() => {
    if (members.length == 1 && modItems.length && allMods && currentTab == 7) {
      const initialValues = {};
      allMods.forEach((mod) => {
        //console.log(mod);
        //console.log('changing allMods!')
        //console.log(modItems);
        const modItem = modItems.find((item) => item.mod === mod);
        //console.log(modItem);
        const inputKey = modItem ? modItem.title : mod;
        //console.log(modItem);
        if (modItem.mod_type == "date" && members[0]?.[inputKey]) {
          // Check if the type is 'date' and the value is not empty
          // Convert the input value to a Date object
          const date = new Date(members[0]?.[inputKey]);
          let formattedValue = members[0]?.[inputKey];
          // Format the date to YYYY-MM-DD, which is the format expected by HTML date inputs
          const year = date.getFullYear();
          const month = `0${date.getMonth() + 1}`.slice(-2); // Add leading 0 if needed
          const day = `0${date.getDate()}`.slice(-2); // Add leading 0 if needed

          formattedValue = `${year}-${month}-${day}`;
          initialValues[inputKey] = formattedValue || null;
        } else {
          initialValues[inputKey] = members[0]?.[inputKey] || null;
        }
      });

      setInputValues(initialValues);
    }
  }, [members, allMods, modItems]); // Only re-run when members, allMods, or modItems changes

  useEffect(() => {
    //console.log('modItems changed: ', modItems)
    if (thisOrderItems.length > 0 && modItems.length > 0 && !creatingForm) {
      setCreatingForm(true);
      const formElements: any[] = [];
      //console.log('creating form...', thisOrderItems, modItems, inputValues)
      const handleInputChange = (modID, value, type?) => {
        let formattedValue = value;

        if (type === "date") {
          // Split the input value to analyze its parts
          const dateParts = value.split("-");

          // Proceed only if all date parts are present
          if (dateParts.length === 3) {
            const year = dateParts[0];
            const month = dateParts[1];
            const day = dateParts[2];

            // Validate the year part more rigorously
            if (year.length === 4 && parseInt(year, 10) > 1900) {
              // Format month and day to ensure they are always two digits
              const formattedMonth = month.padStart(2, "0");
              const formattedDay = day.padStart(2, "0");
              formattedValue = `${year}-${formattedMonth}-${formattedDay}`;
            } else {
              // If the year part is not valid, consider how you want to handle this
              // For example, you might not update the state or you might set an error message
              return; // Exit without updating the state
            }
          } else {
            // If not all parts are present, don't attempt to format the date
            return; // Exit without updating the state
          }
        }

        // Update the state with the validated and potentially formatted date
        setInputValues((prev) => ({ ...prev, [modID]: formattedValue }));
      };

      const handleKeyDown = (e, inputIndex, modId) => {
        if (e.key === "Enter") {
          e.preventDefault();
          if (barcodeBufferRef.current !== "") {
            //console.log('waiting for barcode');
            // Wait for the bufferedBarcode to be available
            setTimeout(() => {
              //console.log('done waiting. value:', barcodeBufferRef.current);
              setInputValues((prev) => ({
                ...prev,
                [modId]: barcodeBufferRef.current,
              }));
            }, 100);
          }

          // Find the next input field and focus on it
          const nextInput = document.querySelectorAll(".modifier-form input")[
            inputIndex + 1
          ] as HTMLElement;
          if (nextInput) {
            nextInput.focus();
          }
        }
      };
      //console.log('all mods: ', allMods);
      allMods.forEach((mod, index) => {
        const filteredModItems = modItems.filter((item) => item.mod === mod);

        if (filteredModItems.length === 1 && filteredModItems[0].mod_type) {
          setCurrentTab(8);
          const modItem = filteredModItems[0];
          const inputKey = `${modItem.title}`; // Unique key for each input

          switch (modItem.mod_type) {
            case "string":
              formElements.push(
                <>
                  {inputKey}
                  <br />
                  <input
                    key={`${inputKey}-${index}`}
                    type="text"
                    value={inputValues[inputKey] || ""}
                    onChange={(e) => {
                      handleInputChange(inputKey, e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
                    placeholder={`Enter ${modItem.title}`}
                  />
                  <br />
                  <br />
                </>
              );
              break;
            case "number":
              formElements.push(
                <>
                  {inputKey}
                  <br />
                  <input
                    key={`${inputKey}-${index}`}
                    type="number"
                    value={inputValues[inputKey] || 0}
                    onChange={(e) =>
                      handleInputChange(
                        inputKey,
                        e.target.value.replace(/^0+/g, "")
                      )
                    }
                    onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
                    placeholder={`Enter ${modItem.title}`}
                  />
                  <br />
                  <br />
                </>
              );
              break;
            case "date":
              formElements.push(
                <>
                  {inputKey}
                  <br />
                  <input
                    key={`${inputKey}-${index}`}
                    type="date"
                    value={inputValues[inputKey] || null}
                    onChange={(e) =>
                      handleInputChange(inputKey, e.target.value, "date")
                    }
                    onKeyDown={(e) => handleKeyDown(e, index, inputKey)}
                  />
                  <br />
                  <br />
                </>
              );
              break;
            case "boolean":
              formElements.push(
                <>
                  {inputKey}
                  <br />
                  <input
                    key={`${inputKey}-${index}`}
                    type="checkbox"
                    checked={!!inputValues[inputKey]}
                    onChange={(e) =>
                      handleInputChange(inputKey, e.target.checked, inputKey)
                    }
                  />
                  <br />
                  <br />
                </>
              );
              break;
            // Add cases for other mod types as needed
          }
        }
      });
      let modifierFormButton;
      if (formElements.length > 0) {
        modifierFormButton = (
          <div
            key={`buttondiv-form`}
            id={"buttondiv-form"}
            className="button"
            style={{
              gridArea: `6 / 2 / span 60 / span 60`,
            }}
          >
            <form className="modifier-form" onSubmit={handleFormSubmit}>
              {formElements}
              <button type="submit" className="submit-mods-button">
                Apply
              </button>
            </form>
          </div>
        );
        setButtons([modifierFormButton]); // Add this form as a 'button' in your grid
      }
      setCreatingForm(false);
    }
  }, [modItems, inputValues]); //create mod form

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Retrieve the last added item
    const lastAddedItemIndex = thisOrderItems.length - 1;
    const lastAddedItem = thisOrderItems[lastAddedItemIndex];
    if (!lastAddedItem.properties) {
      lastAddedItem.properties = [];
    }
    // Apply modifiers from form input values
    Object.entries(inputValues).forEach(([modID, value]) => {
      if (value) {
        const modifier = modItems.find(
          (item) => item.title.toString() === modID
        );
        if (modifier) {
          lastAddedItem.properties.push({
            name: modifier.title,
            value: value.toString(),
          });
        }
      }
    });

    // Update the order items with the modified last item
    setThisOrderItems([
      ...thisOrderItems.slice(0, lastAddedItemIndex),
      lastAddedItem,
    ]);
    setCurrentTab(1);
    setTriggerButtonBuild((prev) => prev + 1);
    setModItems([]);
    setInputValues({});
  };

  useEffect(() => {
    // Check if the currently modifying item is still in the cart
    const isModifyingItemInCart =
      modifyingItemIndex !== null &&
      thisOrderItems[modifyingItemIndex] !== undefined;
    if (!isModifyingItemInCart) {
      // If the item has been removed, reset the form and related states
      setInputValues({});
      setModifyingItemIndex(null); // Or another logic to handle this situation
      setTriggerButtonBuild((prev) => prev + 1);
      //setCurrentTab(1);
      // Reset any other states related to the modifier form as necessary
    }
  }, [
    thisOrderItems,
    modifyingItemIndex,
    setInputValues,
    setModifyingItemIndex,
  ]); //track mod form

  const editMember = (member) => {
    //console.log("Editing member #", member.membership_number);
    // Format dob to YYYY-MM-DD
    const formattedMember = { ...member };
    setEditedMember(formattedMember);
    setIsEditMember(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedMember((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveMember = (event) => {
    event.preventDefault();
    if (editedMember) {
      memberEditDetails(editedMember);
      setIsEditMember(false);
    }
  };

  const memberEditDetails = (editMemberProperties, preserve = false) => {
    const editMemberPropertiesFiltered = {
      membership_number: editMemberProperties.membership_number,
      dob: editMemberProperties.dobstring,
      name: editMemberProperties.name,
      membership_type: editMemberProperties.membership_type,
      barcode: editMemberProperties.barcode,
      sub_id: editMemberProperties.sub_id,
      valid_until: editMemberProperties.valid_until_string,
      alert: editMemberProperties.alert,
    };

    // Construct the body of the request
    const requestBody = JSON.stringify(editMemberPropertiesFiltered);
    //console.log('request body: ', requestBody);
    // Fetch request to create a new member via the API
    fetch("/api/edit-member", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: requestBody,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("Member edited:", data);
        if (!preserve) {
          //look in the members array, find the returned member, and update the member object for them
          const memberIndex = members.findIndex(
            (member) => member.membership_number === data.membership_number
          );
          //console.log('member index: ', memberIndex, data.membership_number);
          if (memberIndex !== -1) {
            const updatedMembers = [...members];
            const updatedMember = { ...members[memberIndex], ...data };
            updatedMembers[memberIndex] = updatedMember;
            setMembers(updatedMembers);
          }
        }
        // Handle success - e.g., update UI or state
      })
      .catch((error) => {
        console.error("Error editing member:", error);
        // Handle error - e.g., show error message to user
      });
  };

  const addMemberBarcode = (e: React.FormEvent, member) => {
    e.preventDefault();
    setIsAddBarcode(true);
    setSelectedMember(member);
    //console.log('editing memeber # ', member.membership_number);
  };

  useEffect(() => {
    const openPopupWindow = (url, windowName, windowFeatures) => {
      window.open(url, windowName, windowFeatures);
    };

    {
      const gridWidth = 60; // Total number of columns
      const tabRows = 5; // Rows reserved for tabs
      let buttonSize; // Size of each button in grid cells

      if (buttonlist.length <= 1) {
        buttonSize = gridWidth; // 60x60 (1x1)
      } else if (buttonlist.length <= 4) {
        buttonSize = gridWidth / 2; // 30x30 (2x2)
      } else if (buttonlist.length <= 9) {
        buttonSize = gridWidth / 3; // 20x20 (3x3)
      } else if (buttonlist.length <= 16) {
        buttonSize = gridWidth / 4; // 15x15 (4x4)
      } else if (buttonlist.length <= 25) {
        buttonSize = gridWidth / 5; // 12x12 (5x5)
      } else {
        buttonSize = gridWidth / 6; // 10x10 (6x6)
      }

      for (let j = 0; j < buttonlist.length; j++) {
        const rowStart =
          Math.floor(j / (gridWidth / buttonSize)) * buttonSize + 1 + tabRows;
        const colStart = (j % (gridWidth / buttonSize)) * buttonSize + 1;
        buttonarea[
          j
        ] = `${rowStart} / ${colStart} / span ${buttonSize} / span ${buttonSize}`;
      }
    } //set button area settings based on number of items

    const newbuttons: JSX.Element[] = [];
    for (let j = 0; j < buttonlist?.length; j++) {
      const btn = buttonlist[j];
      //console.log("button: ", btn);
      const photo = btn.photo || "";
      const hasPhoto = btn.hasPhoto;

      newbuttons.push(
        <div
          key={"buttondiv-" + j}
          id={"buttondiv-" + j}
          onContextMenu={(e) => handleRightClick(e, btn)}
          className={`${btn.title ? "button" : "emptybutton"} ${
            new Date(new Date(btn?.valid_until).setHours(23, 59, 59, 999)) < new Date() ||
            new Date(btn?.valid_starting) > new Date() ||
            //new Date(btn?.last_visit).toLocaleDateString() == new Date().toLocaleDateString() ||
            btn?.redeemed
              ? " inactive"
              : ""
          } ${isDelete ? " isDelete" : ""}
						`}
          style={{
            gridArea: `${buttonarea[j]}`,
            backgroundImage: photo ? `url(${photo})` : "none",
            backgroundSize: photo ? "cover" : "", // or 'contain' based on your requirement
            backgroundPosition: "center",
          }}
          data-index={j} // Store the index of the button
        >
          {buttonlist.length != 1 && btn.membership_type && (
            <span
              className="edit-member"
              onClick={() => searchMembers(btn.membership_number)}
            >
              Details
            </span>
          )}
          {btn.membership_type && !btn.barcode && (
            <span
              className="add-barcode"
              onClick={(e) => {
                e.preventDefault(); // prevent the default behavior of the click event
                e.stopPropagation(); // Stop the event from bubbling up to parent elements
                addMemberBarcode(e, btn);
              }}
            >
              Add Barcode
            </span>
          )}
          {buttonlist.length == 1 && btn.membership_type && (
            <>
              <span className="edit-member" onClick={() => editMember(btn)}>
                Edit
              </span>
              {(btn.email || btn.sub_id) && (
                <span
                  className="all-members"
                  onClick={() => searchMembers(btn.email || btn.sub_id)}
                >
                  View All
                </span>
              )}
            </>
          )}
          {buttonlist.length == 1 && btn.membership_type && (
            <span
              className="new-photo"
              onClick={() => {
                setSelectedMember(btn);
                setShowPhotoUploadForm(true);
              }}
            >
              New Photo
            </span>
          )}
          <span
            key={"button-" + j}
            className={`buttontext 
              ${photo ? "image" : ""} 
              ${btn.membership_type?.includes("Premium") ? " premium" : ""}
              ${
                new Date(btn?.valid_until) < new Date() ||
                new Date(btn?.valid_starting) > new Date() ||
                //new Date(btn?.last_visit).toLocaleDateString() == new Date().toLocaleDateString() ||
                btn?.redeemed
                  ? " inactive"
                  : ""
              }
              `}
            lang="en"
            id={"button-" + j}
          >
            {btn.name || btn.title || ""}
            {btn.redeemed ? (
              <p className="alert">
                <br />
                Already Redeemed on
                <br />
                {new Date(btn.redeemed).toLocaleString()}
              </p>
            ) : (
              ""
            )}
            {new Date(new Date(btn?.valid_until).setHours(23, 59, 59, 999)) < new Date() ? (
              btn.sub_id ? (
                <p className="alert">
                  {btn.membership_type}
                  <br />
                  Payment Due
                  <br />
                  {new Date(btn.valid_until).toLocaleString()}
                </p>
              ) : (
                <p className="alert">
                  {btn.membership_type}
                  <br />
                  <b>Expired on {btn.valid_until.toLocaleDateString()}</b>
                </p>
              )
            ) : (
              ""
            )}
            {btn?.valid_starting > new Date() ? (
              <p className="alert">
                {btn.membership_type}
                <br />
                <b>Not Valid until {btn.valid_starting.toLocaleDateString()}</b>
              </p>
            ) : (
              ""
            )}
            {btn.visitsToday == 1 &&
            isBFF &&
            btn.membership_type.includes("Premium") ? (
              <p className="bffAlert">BFF Available</p>
            ) : (
              ""
            )}
            {btn.currentVisits > 0 ? (
              <p className="alert">Already Included in This Order</p>
            ) : btn.visitsToday > 0 ? (
              <p className="alert">Already Checked In Today</p>
            ) : (
              ""
            )}
            {btn.visitsToday == 2 ? (
              <p className="alert">BFF Already Checked In</p>
            ) : (
              ""
            )}
            {buttonlist.length == 1 && btn.membership_type && (
              <>
                {btn.membership_type && (
                  <>
                    <br />
                    {btn.membership_type}
                  </>
                )}
                {btn.valid_starting && (
                  <>
                    <br />
                    Valid Starting: {btn.valid_starting.toLocaleDateString()}
                  </>
                )}
                {btn.valid_until && (
                  <>
                    <br />
                    Valid Until: {btn.valid_until.toLocaleDateString()}
                  </>
                )}
                {btn.next_payment && (
                  <>
                    <br />
                    Next Payment Due: {btn.next_payment.toLocaleDateString()}
                  </>
                )}
                {btn.dob && (
                  <>
                    <br />
                    DOB: {btn.dob.split("T")[0]}
                  </>
                )}
                {btn.signup_date && (
                  <>
                    <br />
                    Member Since: {btn.signup_date.toLocaleDateString()}
                  </>
                )}

                {btn.payments_remaining && (
                  <>
                    <br />
                    Required Payments Remaining: {btn.payments_remaining}
                  </>
                )}

                {btn.payment_amount && (
                  <>
                    <br />
                    Payment Amount: ${btn.payment_amount}
                  </>
                )}

                {btn.totalPaid > 0 && btn.totalPaid && (
                  <>
                    <br />
                    Total Paid: ${btn.totalPaid}
                  </>
                )}

                {btn.last_visit && (
                  <>
                    <br />
                    Last visit: {btn.last_visit.toLocaleString()}
                  </>
                )}

                {btn.visits && (
                  <>
                    <br />
                    Total Visits: {btn.visits}
                  </>
                )}

                {btn.visits && btn.totalPaid && (
                  <>
                    <br />
                    Paid Per Visit:{" "}
                    {Math.round((btn.totalPaid / btn.visits) * 100) / 100}
                  </>
                )}

                {btn.edit_url && (
                  <>
                    <br />
                    <br />
                    <span
                      onClick={() =>
                        openPopupWindow(
                          btn.edit_url,
                          "newWindow",
                          "width=800,height=600"
                        )
                      }
                    >
                      View Subscription Portal
                    </span>
                  </>
                )}
              </>
            )}
            {btn.membership_type && btn.alert && (
              <>
                <br />
                <span className="member-alert">ALERT: {btn.alert}</span>
              </>
            )}
            {btn.price > 0 ? (
              <>
                <br />
                <span className="buttonprice" id={"buttonprice-" + j}>
                  ${btn.price.toFixed(2) || ""}
                </span>
              </>
            ) : (
              ""
            )}
            {btn.subtitle ? (
              <>
                <br />
                <span className="buttonsubtotal" id={"buttonsubtitle-" + j}>
                  {btn.subtitle}
                </span>
              </>
            ) : (
              ""
            )}
            {btn.date ? (
              <>
                <br />
                <span className="buttonsubtotal" id={"buttondate-" + j}>
                  Order Placed: {btn.date}
                </span>
              </>
            ) : (
              ""
            )}
          </span>
        </div>
      );
    }
    if (currentTab != 8) setButtons(newbuttons); //create buttons[] from buttonlist
    //end grid creation
  }, [buttonlist, triggerButtonBuild, isDelete]); //build buttons from buttonlist

  //function addItem(e){
  type SoundName =
    | "add"
    | "block1"
    | "block2"
    | "bloop1"
    | "bloop2"
    | "bloop3"
    | "chaching"
    | "pop1"
    | "pop2"
    | "pop3"
    | "pop4"
    | "pop5"
    | "pop6"
    | "pop7"
    | "pop8"
    | "pop9";

  async function addItem(itemOrEvent) {
    //console.log('addItem', itemOrEvent);
    let sound: SoundName = "pop7";
    let newItem: Types.ExtendedLineItem;
    if (itemOrEvent && itemOrEvent.target) {
      // Handle MouseEvent as before
      const target = itemOrEvent.target;
      const id = target.id.split("-")[1];
      if (!id) return;
      newItem = { ...buttonlist[parseInt(id, 10)] }; // Get the item from buttonlist
      //console.log('newItem', newItem)
    } else {
      // Handle object directly
      newItem = { ...itemOrEvent }; // Assume itemOrEvent is the object with item details
    }
    //console.log('newItem', newItem)
    if (newItem.price < 0) {
      newItem.price = Math.abs(newItem.price);
      newItem.quantity = -newItem.quantity || -1;
    }
    let newOrder = [];

    if (orderId) {
      clearOrder(true, false); // Wait for clearOrder to finish
      //wait for clearOrder to finish
      await new Promise((resolve) => setTimeout(resolve, 200));
    } else {
      newOrder = [...thisOrderItems];
    }

    if (newItem.membership_type) {
      if (newItem.membership_type.includes("Premium")) {
        sound = "bloop3";
        //check if discountCodes contains a premium discount code and add it if not
        if (!discountCodes.find((dc) => dc.code.includes("PREMIUM"))) {
          setDiscountCodes([...discountCodes, {
            code: "PREMIUM",
            amount: 20,
            type: "percentage", }]);
        }
      } else {
        sound = "bloop1";
      }
      if(!customer.email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(newItem.email)) {
        setCustomer({
          ...customer,
          email: newItem.email,
        });
      }
    }

    if (newItem.membership_type && newItem.photo == nophoto) {
      if (newItem.photo == nophoto) {
        // If the member has no photo, set the selected member and show the upload form
        setSelectedMember(newItem);
        setShowPhotoUploadForm(true);
      } else {
        // Otherwise, proceed with your existing logic (e.g., showing member details)
        searchMembers(newItem.membership_number);
      }
    }

    if (
      new Date(new Date(newItem?.valid_until).setHours(23, 59, 59, 999)) < new Date() ||
      newItem?.valid_starting > new Date()
    ) {
      soundManager.play("block1");
      return;
    }
    if (newItem.visitsToday == 1) {
      if (isBFF && newItem.title.includes("Premium")) {
        newItem.title = `BFF Checkin`;
        newItem.attendance_category = "BFF";
        newItem.calendar_category = null;
        sound = "bloop2";
      } else {
        soundManager.play("block1");
        //('already checked in')
        return;
      }
    } else if (newItem.visitsToday > 1) {
      soundManager.play("block1");
      return;
    }

    let matchedIndex = -1;

    if (newItem.function == "searchOrders") {
      return setOrder(newItem.order);
      //searchOrders(newItem.title);
    } //if search function button
    if (newItem.email || newItem.sub_id) {
      searchMembers(newItem.email || newItem.sub_id);
      //setSubSearch(newItem.sub_id)
    } //if member, find related members for suggestions
    if (!newItem.title && !newItem.name) {
      soundManager.play("block1");
      return;
    } //exit if not a plu item button
    if (/^-?\d+$/.test(typedValue)) {
      //console.log('quantity detected', typedValue, parseInt(typedValue, 10))
      newItem.quantity = parseInt(typedValue, 10);
      //console.log('newItem', newItem)
      if (newItem.quantity < 10000) {
        setTypedValue("");
      } else {
        newItem.quantity = 1;
      }
      if (newItem.max_quantity && newItem.max_quantity < newItem.quantity) {
        newItem.quantity = newItem.max_quantity;
      }
    } //set quantity if number is typed
    //console.log('newItem', newItem)
    //if the delete key is being held down, make the quantity negative
    if (isDelete) {
      newItem.quantity = -newItem.quantity;
    }

    //test if typedValue is in the form 300*2299 or 5x1299 for custom rates on item
    if (
      newItem.function?.includes("customrate") &&
      /^[-\d]+([*x])(\d+(?:\.\d+)?)$/.test(typedValue)
    ) {
      newItem.quantity = parseFloat(typedValue.split(/[*x]/)[0]);

      if (newItem.quantity < 100000) {
        setTypedValue("");
      } else {
        newItem.quantity = 1;
      }
      if (newItem.max_quantity && newItem.max_quantity < newItem.quantity) {
        newItem.quantity = newItem.max_quantity;
      }
      const ratestring = typedValue.split(/[*x]/)[1];
      //let rate = typedValue.split(/[*x]/)[1]
      //if it doesn't have a decimal, divide by 100
      let rate = 0;
      if (!/\./.test(ratestring)) {
        if (Number(ratestring) < 1) {
          rate = Number(ratestring);
        } else {
          rate = Number(ratestring) / 100;
        }
      } else {
        rate = Number(ratestring);
      }

      newItem.price = rate;
      newItem.fullPrice = rate;
    } else {
      newItem.fullPrice = newItem.price;
    }
    //console.log('newItem', newItem)
    if (
      currentTab !== 10 &&
      newItem.modClass &&
      newItem.modClass > 999 &&
      Math.floor(newItem.modClass / 1000) != modClass
    ) {
      //console.log('blocked!')
      soundManager.play("block1");

      return;
    } //if item has mods and the mods it needs are not loaded
    setNumOfMods(
      (prevNumOfMods) =>
        Math.max(prevNumOfMods - 1, 0) + (newItem.numOfMods || 0)
    );

    if (!newItem.isMod || Math.floor(newItem.modClass / 1000) === modClass) {
      setModClass(newItem.modClass % 1000 || null);
      if (newItem.modClass % 1000 === 0) {
        setParentModClass(null);
      }
    }

    if (!newItem.isMod) {
      setParentModClass(newItem.modClass || 0);
    }
    setModUpdateTrigger((prev) => !prev);
    setReqMods(newItem.required_mods || []);
    setOptMods(newItem.optional_mods || []);
    setAllMods(
      (newItem.required_mods || []).concat(newItem.optional_mods || [])
    );

    const modsToFetchArray = (newItem.required_mods || []).concat(
      newItem.optional_mods || []
    );
    // Remove duplicates
    //console.log('modsToFetchArray', modsToFetchArray)
    const uniqueModsToFetchArray = [...new Set(modsToFetchArray)];

    // Check which mod classes are not in the cache
    if (!newItem.isMod) {
      // All mods are in cache (including those with empty arrays)
      const cachedMods = uniqueModsToFetchArray.flatMap((modClass) => {
        const mods = modClassesData[modClass] || [];
        return mods.map((mod) => ({ ...mod })); // Clone mods to prevent mutation
      });
      setModItems(cachedMods);
    }

    if (newItem.isMod) {
      addModifierToItem(newItem, newOrder);
      soundManager.play("pop1");
    } else {
      const compareItems = (
        item1: Types.ExtendedLineItem,
        item2: Types.ExtendedLineItem
      ) => {
        const { quantity: quantity1, photo: photo1, ...rest1 } = item1;
        const { quantity: quantity2, photo: photo2, ...rest2 } = item2;
        //console.log('comparing: ',item1,item2)
        return (
          quantity1 &&
          quantity2 &&
          JSON.stringify(rest1) === JSON.stringify(rest2)
        );
      };
      for (let i = 0; i < newOrder.length; i++) {
        if (compareItems(newItem, newOrder[i])) {
          if (
            newItem.max_quantity &&
            newOrder[i].quantity + newItem.quantity > newItem.max_quantity
          ) {
            newItem.quantity = 0;
          }
          newOrder[i] = {
            ...newOrder[i],
            quantity: newOrder[i].quantity + newItem.quantity,
          }; // Create a new object with updated quantity
          matchedIndex = i;
          break;
        }
      } //add to quantity if already exists
      if (matchedIndex !== -1) {
        const matchedItem = newOrder.splice(matchedIndex, 1)[0]; // remove the matched item
        newOrder.push(matchedItem); // add it back to the end
      } else {
        newOrder.push(newItem); // if no match, simply add the new item to the end
      }
      // Look for the item in the existing order
      const itemIndex = newOrder.findIndex((item) => item === newItem);
      // If the item exists, set it as the current modifying item
      if (itemIndex !== -1) {
        setModifyingItemIndex(itemIndex);
      }
      setThisOrderItems(newOrder);
      soundManager.play(sound);
    }
  }

  function addModifierToItem(
    modifier: Types.ExtendedLineItem,
    newOrder: Types.LineItem[]
  ) {
    //console.log('modifier',modifier)
    if (modifyingItemIndex !== null) {
      const currentItem = newOrder[modifyingItemIndex];
      if (modifier.id) {
        currentItem.id = modifier.id;
      }
      if (modifier.variant_id) {
        currentItem.variant_id = modifier.variant_id;
      }
      if (modifier.function == "overwrite") {
        //console.log('overwriting', currentItem, modifier)
        currentItem.title = modifier.title;
        currentItem.price = modifier.price;
        currentItem.sku = modifier.sku;
        currentItem.properties = [];
        if (modifier.quantity < 0) {
          currentItem.quantity = -currentItem.quantity;
          //console.log('this should be negative', currentItem.quantity)
        }
        //currentItem.quantity = modifier.quantity;
      } else {
        if (!currentItem.properties) {
          currentItem.properties = [];
        }

        if (modifier.discountType) {
          let discAmt = 0;
          if (modifier.discountType == "%") {
            discAmt = parseFloat(
              ((currentItem.price * modifier.price) / 100).toFixed(2)
            );

            currentItem.properties.push({
              name: "Mod",
              value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
            });
            currentItem.price -= discAmt;
          } else if (modifier.discountType == "$") {
            discAmt = parseFloat(modifier.price.toFixed(2));
            currentItem.properties.push({
              name: "Mod",
              value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
            });
            currentItem.price -= discAmt;
          } else if (modifier.discountType == "o") {
            discAmt = parseFloat(
              (currentItem.price - modifier.price).toFixed(2)
            );
            currentItem.properties.push({
              name: "Mod",
              value: `${modifier.title} (-$${discAmt.toFixed(2)})`,
            });
            currentItem.price = modifier.price;
          }
        } else if (modifier.addPrice) {
          currentItem.properties.push({
            name: "Mod",
            value: `${modifier.title} (+$${modifier.price.toFixed(2)})`,
          });
          currentItem.price += modifier.price;
        } else
          currentItem.properties.push({
            name: "Mod",
            value: `${modifier.title}`,
          });
      }

      setThisOrderItems(newOrder);
    }
  }

  const handleBarcodeSubmit = async (event) => {
    event.preventDefault();
    //console.log('current item: ', contextMenu.currentItem);
    // Assuming `selectedPLUItem` holds the necessary item data, like an ID
    const pluItemId = contextMenu.currentItem?.sku || ""; // Ensure you have an 'id' or similar identifier

    const apiUrl = "/api/create-barcode"; // Your actual API endpoint
    const payload = {
      plu_id: pluItemId, // or any other identifier for the PLU item
      barcode: barcode,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`, // if you need authentication
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        //console.log('Barcode added successfully:', result);
        // Perform any follow-up actions like updating the UI or state
        setShowBarcodeModal(false);
        setBarcode(""); // Reset the barcode input
      } else {
        // Handle errors
        const error = await response.json();
        console.error("Error adding barcode:", error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const addBarcode = (item) => {
    //console.log("Adding barcode to", item);
    // Implement your logic to add a barcode here (e.g., show a form modal)
    setShowBarcodeModal(true);
    setContextMenu({ visible: false, x: 0, y: 0, currentItem: item }); // Close context menu
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the form from submitting
      const formElements = e.target.form.elements; // Get all form elements
      const currentIndex = Array.prototype.indexOf.call(formElements, e.target);

      // Move focus to the next input
      const nextElement = formElements[currentIndex + 1];
      if (nextElement && nextElement.tagName === "INPUT") {
        nextElement.focus();
      }
    }
  };

  return (
    <div key="buttongrid" className="ButtonGrid" onClick={addItem}>
      <div className="tabs" style={{ gridArea: "1 / 1 / 6 / 61" }}>
        <>
          <button
            className={`tab-button ${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}
          >
            Admission
          </button>
          <button
            className={`tab-button ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}
          >
            Merch
          </button>
          <button
            className={`tab-button ${currentTab === 3 ? "active" : ""}`}
            onClick={() => setCurrentTab(3)}
          >
            Food
          </button>
          <button
            className={`tab-button ${currentTab === 11 ? "active" : ""}`}
            onClick={() => setCurrentTab(11)}
          >
            Drinks
          </button>
        </>
        {modClass > 0 ? (
          <button
            className={`tab-button ${currentTab === 5 ? "active" : ""}`}
            onClick={() => {
              setCurrentTab(5);
            }}
          >
            Item Modifiers
          </button>
        ) : (
          ""
        )}
        {orderSearchResults.length >= 1 && (
          <button
            className={`tab-button ${currentTab === 6 ? "active" : ""}`}
            onClick={() => setCurrentTab(6)}
          >
            Order Search
          </button>
        )}
        {members.length > 0 && (
          <button
            className={`tab-button ${currentTab === 7 ? "active" : ""}`}
            onClick={() => setCurrentTab(7)}
          >
            Member Search
          </button>
        )}
        {modItems.length > 0 && (
          <button
            className={`tab-button ${currentTab === 8 ? "active" : ""}`}
            onClick={() => setCurrentTab(8)}
          >
            Inputs
          </button>
        )}
        {giftCards.length > 0 && (
          <button
            className={`tab-button ${currentTab === 9 ? "active" : ""}`}
            onClick={() => setCurrentTab(9)}
          >
            Gift Cards
          </button>
        )}
        <button
          className={`tab-button ${currentTab === 10 ? "active" : ""}`}
          onClick={() => setCurrentTab(10)}
        >
          Modifiers
        </button>
      </div>
      {showBarcodeModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleBarcodeSubmit(e);
                //console.log('Submitting barcode:', barcode);
                setShowBarcodeModal(false);
              }}
            >
              <label htmlFor="barcode">Barcode:</label>
              <input
                id="barcode"
                type="text"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
              <button type="submit">Add Barcode</button>
              <button type="button" onClick={() => setShowBarcodeModal(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {isAddBarcode && (
        <div className="modal-overlay">
          <div className="modal-content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                //const { name, membership_type, dob, sub_id, barcode, membership_number } = req.body;
                //console.log('Submitting barcode:', barcode);
                const requestBody = JSON.stringify({
                  membership_number: selectedMember?.membership_number,
                  barcode: barcode,
                  dob: selectedMember?.dobstring,
                  membership_type: selectedMember?.membership_type,
                  sub_id: selectedMember?.sub_id,
                  name: selectedMember?.name,
                  valid_until: selectedMember?.valid_until_string,
                  alert: selectedMember?.alert,
                });

                // Fetch request to create a new member via the API
                fetch("/api/edit-member", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                  },
                  body: requestBody,
                })
                  .then((response) => response.json())
                  .then((data) => {
                    //console.log("Member edited:", data);
                    // Handle success - e.g., update UI or state
                  })
                  .catch((error) => {
                    console.error("Error editing member:", error);
                    // Handle error - e.g., show error message to user
                  });
                setIsAddBarcode(false);
                //search through current buttons for the member we just edited and update the barcode property
                const editedMemberIndex = buttonlist.findIndex(
                  (member) =>
                    member.membership_number ===
                    selectedMember?.membership_number
                );
                //console.log('editedMemberIndex', editedMemberIndex)
                buttonlist[editedMemberIndex].barcode = barcode;
                setBarcode("");
                setTriggerButtonBuild((prev) => prev + 1);
              }}
            >
              <label htmlFor="barcode">Barcode:</label>
              <input
                id="barcode"
                type="text"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
              <button type="submit">Add Barcode</button>
              <button type="button" onClick={() => setIsAddBarcode(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
      {contextMenu.visible && (
        <div
          className="context-menu"
          style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
        >
          <ul>
            <li onClick={() => addBarcode(contextMenu.currentItem)}>
              Add Barcode
            </li>
          </ul>
        </div>
      )}
      {isEditMember && editedMember && (
        <>
          <div
            className="modal-overlay"
            onClick={() => setIsEditMember(false)}
          ></div>
          <div className="modal-content">
            <div className="">
              <h1>Edit Membership Details</h1>
              <form onSubmit={handleSaveMember}>
                <label>
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={editedMember.name || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Membership Type:
                  <input
                    type="text"
                    name="membership_type"
                    value={editedMember.membership_type || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Date of Birth:
                  <input
                    type="date"
                    name="dobstring"
                    value={editedMember.dobstring || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Subscription ID:
                  <input
                    type="text"
                    name="sub_id"
                    value={editedMember.sub_id || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Barcode:
                  <input
                    type="text"
                    name="barcode"
                    value={editedMember.barcode || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Valid Until:
                  <input
                    type="date"
                    name="valid_until_string"
                    value={editedMember.valid_until_string || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <label>
                  Alert:
                  <input
                    type="text"
                    name="alert"
                    value={editedMember.alert || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterPress(e)}
                  />
                </label>
                <br />
                <button type="submit">Save</button>
                <button type="button" onClick={() => setIsEditMember(false)}>
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </>
      )}
      {showPhotoUploadForm && selectedMember && (
        <PhotoUploadForm
          selectedMember={selectedMember}
          onClose={() => {
            setShowPhotoUploadForm(false);
          }}
          setMembers={setMembers}
          members={members}
        />
      )}

      {buttons ? buttons : ""}
    </div>
  );
};
export default ButtonGrid;
